#manufacturer-treads-filter-menu {

    .mainmenu,
    .submenu {
        line-height: 6rem;
        min-height: auto;
        padding: 0px 2rem;
        border: none;
        font-size: 2.5rem;

        .default.text {
            color: @marso-gray-85;
        }

        .text {
            .image {
                display: none;
            }
        }

        .menu {
            box-shadow: 0px 2px 3px 0px fade(@marso-gray-85, 30%);
            border: none;
            margin: 0px;
            width: 100%;
            min-width: 100%;
            max-height: none;

            .item {
                border-color: @marso-gray-06  !important;
                border-width: 2px;
                font-size: 2.2rem;
                line-height: 5rem;
                max-height: 6.2rem;
                padding: 0px 2rem 0px 0px !important;

                .image {
                    display: inline-block;
                    min-width: 15rem;
                    max-width: 15rem;
                    height: 6rem;
                    min-height: 6rem;
                    max-height: 6rem;
                    padding: 0.5rem 2rem;
                    background-color: @marso-gray-20;

                    img {
                        height: 5rem;
                        max-height: 5rem;
                        width: 100%;
                    }
                }

                .content {
                    vertical-align: middle;
                }
            }
        }
    }

    .submenu {
        .menu .item {
            padding: 0px 2rem !important;
        }
    }

    .dropdown.icon {
        font-size: 1.5rem;
        line-height: 6rem;
        margin: 0;
        padding: 0;
        right: 2rem;
        top: 0;
    }

    >.container {
        padding: 3rem 0;

        .title {
            font-size: 6rem;
            line-height: 6rem;
            font-weight: normal;
        }
    }
}

.manufacturer-page {
    .manufacturer-description {
        padding: 3rem 0;
    }
}

#content-manufacturers {
    padding-bottom: 3rem;
}

/* treads block */
.content-tread {
    position: relative;
    min-height: 38rem;

    >.image {
        float: right;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        max-height: 35rem;
        overflow: hidden;
    }

    .product-name-wrapper {
        padding-top: 20%;
        height: 22rem;

        .manufacturer-name {
            font-weight: bold;
        }

        .tread-name {
            text-shadow:
                1px 0px 0px fade(@white, 95%),
                0px 1px 0px fade(@white, 95%),
                -1px 0px 0px fade(@white, 95%),
                0px -1px 0px fade(@white, 95%);
        }

        .name {
            font-weight: bold;
        }

        &.big {
            font-size: 2rem;
            line-height: 3rem;
        }

        &.huge {
            font-size: 3.5rem;
            line-height: 4rem;
        }
    }

    .description {
        background-color: @white;
        border-top: 2px solid @marso-gray-10;
        max-height: 17rem;
        min-height: 17rem;
        overflow: hidden;
        padding-top: 1rem;

        .more {
            display: none;
        }

        &.full {
            max-height: none;
            padding-bottom: 7rem;

            .less {
                display: none;
            }

            .more {
                display: block;
            }
        }

        .button-more-less-switch {
            color: @marso-green  !important;

            &.link {
                background-color: @white  !important;
                padding: 0px;
            }

            .icon {
                line-height: 1rem !important;
                width: 1.2rem !important;
                height: 1.2rem !important;
                font-size: 0.8rem;
                padding: 0.1rem 0.1rem 0.1rem 0.1rem !important;
                margin: 0 !important;
            }
        }
    }

    .button-goto-products {
        box-shadow: 0px -2px 2px 0px fade(@marso-gray-85, 30%);
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: @marso-gray-10;
        display: block;
        width: 100%;
        height: 4rem;

        a {
            height: 4rem;
            line-height: 4rem;
            display: block;
            color: @marso-gray-80;
            font-weight: bold;
            padding: 0 0.5rem 0 1rem;
            font-size: 1.8rem;

            .icon {
                float: right;
                color: @marso-gray-30;
            }
        }
    }
}
