#debug {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 998;
    background-color: rgba(51, 51, 51, 0.2);

    .ui.container {
        height: 100%;
        outline: 1px solid red;

        .grid {
            height: 100%;

            .column {
                position: relative;
                height: 100%;
                background-color: rgba(51, 51, 51, 0.1);
                outline: 1px solid blue;

                &:before {
                    content: " ";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 1rem;
                    height: 100%;
                    background-color: rgba(0, 0, 255, 0.2);
                }

                &:after {
                    content: " ";
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 1rem;
                    height: 100%;
                    background-color: rgba(0, 0, 255, 0.3);
                }
            }
        }
    }
}

.debug-buttons {
    top: 0;
    left: 0;
    z-index: 99999;
    position: fixed;
}

body {
    &.show-grids {
        .ui.grid {
            outline: 3px solid lightgreen;

            .column {
                outline: 1px dashed orange;
            }

            &.column {
                outline: 3px dashed lightgreen !important;
            }
        }
    }

    &.show-images {
        img {
            outline: 1px dotted darkblue;
        }
    }
}
