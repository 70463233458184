#car-data-form {
    .car-tyre-sizes-fields {
        .field-label-wrapper {
            width: 100%;
            .field-label {
                border-bottom-left-radius: 0;
                border-top-right-radius: 0.5rem;
            }
        }
        .field-input-wrapper {
            width: 100%;
            margin-left: 0;
            .field-input {
                border-bottom-left-radius: 0.5rem;
                border-top-right-radius: 0;
            }
        }
    }

    .question-box {
        background-color: rgba(255, 255, 255, 0.5);
        color: @marso-gray-80;
    }
}
