@inputWidth: 100%;
@inputFont: @pageFont;
@inputFontSize: 1em;
@inputBorder: 1px solid @borderColor;
@inputBorderRadius: @absoluteBorderRadius;
@inputBoxShadow: 0em 0em 0em 0em transparent inset;
@inputTransition: color @defaultDuration @defaultEasing,
    border-color @defaultDuration @defaultEasing;

// border radius change on focus, eliminate this
.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="url"]:focus {
    border-radius: unset;
}

.ui.form textarea:focus {
    border-radius: unset;
}

// form block default (inactive)
.form-block {
    margin-bottom: 3rem;
    border-radius: 0.5rem;

    >.top.attached.header {
        background-color: @marso-gray-10;
        color: @marso-gray-30;
        height: 6rem;
        padding: 1rem 3rem;
        line-height: 4rem;
        text-transform: uppercase;
        font-size: 2.2rem !important;
        border: none;
        margin: 0;
        width: 100%;
        border-radius: 0.5rem;
    }

    >.attached.segment {
        display: none;
        margin: 0;
        width: 100%;
    }

    >.attached.button {
        display: none;
        padding: 1.5rem;
        box-shadow: none !important;
        opacity: 1;
    }

    .ui.grid>select {
        padding: 0 1rem !important;
    }

    .header-select {
        display: none;
    }
}

.form-block.mobile {
    >.top.attached.header {
        font-size: 1.75rem !important;
        border-radius: 0;
    }

    >.bottom.attached {
        border-radius: 0;
    }
}

// form block active
.form-block.active {
    margin-bottom: 3rem;
    background-color: @marso-gray-10;

    >.top.attached.header {
        border-radius: 0.5rem 0.5rem 0 0;
        color: @white;
        background-color: @marso-gray-80;
    }

    >.attached.content {
        display: block;
        border-top: 2px solid @marso-brand  !important;
        padding-top: 2rem;
    }

    >.attached.segment {
        display: block;
        background-color: @marso-gray-10;
        border: none
    }

    >.attached.button {
        display: block;
    }

    .header-select {
        display: block;
    }
}

.form-block.mobile.active {
    >.top.attached.header {
        border-radius: 0;
    }
}

.form-block {
    &>.content {
        background-color: @marso-gray-06;
    }

    // normal field
    .field {
        position: relative;

        .field-label-wrapper {
            display: block;
            float: left;
            width: 25%;

            .field-label {
                display: inline-block;
                width: 100%;
                line-height: 4rem;
                padding: 0.5rem 1rem;
                font-size: 1.6rem;
                font-weight: bold;
                text-transform: uppercase;
                background-color: @marso-gray-30;
                color: @white;
                border-radius: 0.5rem 0 0 0.5rem;

                &:after {
                    display: none !important;
                }
            }
        }

        .field-input-wrapper {
            display: block;
            margin-left: 25%;
            width: 75%;

            .field-input {
                display: inline-block;
                border-radius: 0 0.5rem 0.5rem 0;
                border: none;
                line-height: 4rem;
                padding: 0.5rem 1rem;
                height: 5rem; // ie11 fix

                &:focus {
                    border-radius: 0 0.5rem 0.5rem 0;
                }

                &::placeholder {
                    font-size: 1.2rem;
                    color: @marso-gray-30;
                    opacity: 1;
                    text-align: right;
                }
            &:disabled {
                cursor: not-allowed;
                pointer-events: auto;
            }
            }
        }

        .prompt.label {
            position: absolute;
            top: -3rem;
            left: 20%;
        }
    }

    // field without label
    .field.no-label {
        .field-label-wrapper {
            display: none;
        }

        .field-input-wrapper {
            float: none;
            width: 100%;
            margin-left: 0;

            .field-input {
                border-radius: 0.5rem;
            }
        }
    }

    .field.long-label {
        .field-label-wrapper {
            width: 35%;
        }

        .field-input-wrapper {
            width: 65%;
            margin-left: 35%;
        }
    }

    // select field
    .field.select-field {
        .field-input-wrapper {
            .field-input {
                height: 5rem;

                option {
                    height: 5rem;
                    line-height: 4rem;
                    padding: 0.5rem 1rem;
                }
            }

            .field-input.dropdown {
                max-height: 5rem;
                min-height: auto;
                width: 100%;
                border: 1px solid @marso-gray-10;

                .search {
                    padding: 0.5rem 1rem;
                    line-height: 4rem;
                }

                .text {
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 100%;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .field.small-field.checkbox-field {
        .field-input-wrapper {
            font-size: 1.4rem;

            .field-input {
                line-height: 2rem;
                height: 3rem;

                &[for="newsletter"],
                &[for="gtc_accept"] {
                    height: auto;
                }
            }
        }
    }
}

.form-block.vertical {
    .field {
        .field-label-wrapper {
            width: 50%;

            .field-label {
                border-radius: 0.5rem 0.5rem 0 0;
                line-height: 2rem;
            }
        }

        .field-input-wrapper {
            width: 100%;
            float: none;
            margin-left: 0;

            .field-input {
                border-radius: 0 0.5rem 0.5rem 0.5rem;

                &:focus {
                    border-radius: 0 0.5rem 0.5rem 0.5rem;
                }
            }
        }

        .prompt.label {
            position: absolute;
            top: 0;
            left: 30%;
        }
    }

    .field.wide-label {
        .field-label-wrapper {
            width: 100%;
        }

        .field-input-wrapper {
            .field-input {
                border-top-right-radius: 0;
            }
        }
    }

    .field.login-field {
        font-size: 1.75rem;

        .field-label-wrapper {
            .field-label {
                background-color: transparent;
                text-transform: none;
                text-align: center;
                color: @marso-gray-80;
                font-weight: normal;
            }
        }

        .field-input-wrapper {
            .field-input {
                border-radius: 0.5rem;

                &::placeholder {
                    font-size: 1.75rem;
                    text-align: left;
                }
            }
        }

        .prompt.label {
            position: absolute;
            top: 0;
            left: 1rem;
        }
    }

    .field.login-field.small-field {
        .prompt.label {
            top: -2rem;
        }
    }
}

.field.autocomplete-field {
    .field-input-wrapper {
        position: relative;

        .field-input {
            padding-right: 4rem;
        }

        .search.icon {
            position: absolute;
            right: 0;
            bottom: 0;
            line-height: 4rem;
            padding: 0.5rem 1rem;
            display: block;
            height: 5rem;
            width: 4rem;
            color: @marso-gray-40;
        }
    }

    .ui.search.dropdown {
        padding: 0.5rem 1rem;
        border: 0;

        input.search {
            padding: 0.5rem 1rem;
            left: 0;
        }

        input.search,
        .text {
            line-height: 4rem;
            padding-right: 4rem;
        }

        .default.text {
            font-size: 1.25rem;
            color: @marso-gray-30;
            opacity: 1;
            text-align: right;
        }

        .search.icon {
            right: 1.75rem;
        }
    }
}

// checkbox field
.field.checkbox-field {
    .field-input-wrapper {
        width: 100%;
        margin-left: 0;

        .checkbox-label {
            //display: flex;
            display: block;
            border-radius: 0.5rem;
            background-color: @white;

            .field-label {
                //display: block;
                padding: 0 0.5rem;
            }

            &.multiline {
                height: auto !important;
            }
        }

        &.right-switch {
            .switch {
                float: right;
            }
        }
    }

    &.simple {
        .field-input-wrapper {
            .checkbox-label {
                background-color: transparent;
            }
        }
    }

    &.gray {
        .field-input-wrapper {
            .checkbox-label {
                background-color: @marso-gray-10;
            }
        }
    }
}

.field.textarea-field {
    .field-label-wrapper {
        width: 100%;
        float: none;

        .field-label {
            border-radius: 0.5rem 0.5rem 0 0;
        }
    }

    .field-input-wrapper {
        width: 100%;
        margin-left: 0;

        .field-input {
            border-radius: 0 0 0.5rem 0.5rem;
            min-height: 5rem;
            height: auto;
            line-height: 2rem;

            &:focus {
                border-radius: 0 0 0.5rem 0.5rem;
            }
        }
    }
}

// zip code search form
#zip-code-form {
    display: none;
}

.form-block.active #zip-code-form {
    display: block;
}

.field.service-site-search-field {
    float: right;

    .field-input-wrapper {
        width: 10rem;
        float: left;
        margin-left: 0;

        .field-input {
            border-radius: 0.5rem 0 0 0.5rem;
            line-height: 3rem;
            height: 4rem;

            &:focus {
                border-radius: 0.5rem 0 0 0.5rem;
            }
        }
    }

    .field-button-wrapper {
        //display: inline-block;
        width: auto;
        float: left;

        .field-button {
            border-radius: 0 0.5rem 0.5rem 0;
            line-height: 3rem;
            padding: 0.5rem 1rem;
            text-transform: uppercase;
        }
    }

    .prompt.label {
        bottom: 3rem;
        left: -3rem;
        text-align: center;
        top: auto;
    }
}

.form-block.mobile {
    .field.service-site-search-field {
        .field-input-wrapper {
            width: 50%;
            display: inline-block;
            float: none;

            .field-input {
                border-radius: 0.5rem 0 0 0.5rem;
            }
        }

        .field-button-wrapper {
            width: 45%;
            display: inline-block;
            float: right;

            .field-button {
                width: 100%;
                text-align: center;

                .icon {
                    font-size: 2rem;
                    line-height: 2rem;
                    margin-right: 0;
                }
            }
        }

        .prompt.label {
            bottom: 3rem;
            left: -3rem;
            text-align: center;
            top: auto;
        }
    }
}

// header select
.form-block {
    .field.header-select {
        .field-label-wrapper {
            float: left;
            width: 25%;

            .field-label {
                border-radius: 0.5rem 0 0 0.5rem;
                line-height: 2rem;
                font-size: 1.5rem;
            }
        }

        .field-input-wrapper {
            width: 75%;

            .field-input {
                border-radius: 0 0.5rem 0.5rem 0;
                line-height: 2rem;
                font-size: 1.5rem;
                height: 3rem;
            }
        }
    }

    .field.header-select.no-label {
        .field-label-wrapper {
            display: none;
        }

        .field-input-wrapper {
            float: none;
            width: 100%;
            margin-left: 0;

            .field-input {
                border-radius: 0.5rem;
            }
        }
    }
}

// info box field in the corner
.form-block {
    .field {
        .field-input-wrapper.with-info-box {
            .info-box-switch {
                position: absolute;
                top: 0;
                right: 0;
                overflow: hidden;
                width: 4.5rem;
                height: 4.5rem;
                border-top-right-radius: 0.5rem;
                text-align: right;
                z-index: 1;

                &:after {
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 0;
                    z-index: -1;
                    width: 0;
                    height: 0;
                    background-color: transparent !important;
                    border-top: 0 solid transparent;
                    border-right: 4.5rem solid @marso-gray-30;
                    border-bottom: 4.5rem solid transparent;
                    border-left: 0 solid transparent;
                }
            }

            .info-box {
                position: absolute;
                top: 0;
                width: inherit;
                line-height: 4rem;
                padding: 0.5rem 1rem;
                background-color: @marso-red;
                border-radius: 0 0.5rem 0.5rem 0;
                color: @white;
            }
        }
    }

    &.vertical {
        .field {
            .field-input-wrapper.with-info-box {
                .info-box-switch {
                    top: auto;
                    bottom: 0.5rem;
                }

                .info-box {
                    bottom: 0;
                    top: auto;
                    border-radius: 0 0.5rem 0.5rem;
                }
            }
        }
    }
}

//
// copied elements from form.less
//
.field.file-field {
    .field-input-wrapper {
        position: relative;

        input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;

            &+label {
                cursor: pointer;

                width: @inputWidth;
                vertical-align: top;

                font-family: @inputFont;
                margin: 0em;
                outline: none;
                -webkit-appearance: none;

                line-height: @inputLineHeight;
                padding: @inputPadding;
                font-size: @inputFontSize;

                background: @inputBackground;
                border: @inputBorder;
                color: @marso-gray-30;
                border-radius: @inputBorderRadius;
                box-shadow: @inputBoxShadow;
                transition: @inputTransition;

                //
                // copied block from marso-form.less
                //
                display: inline-block;
                border-radius: 0 0.5rem 0.5rem 0;
                border: none;
                line-height: 4rem;
                padding: 0.5rem 1rem;
                height: 5rem; // ie11 fix

                &.file-selected {
                    color: @inputColor;
                }
            }
        }
    }
}
