.page-checkout {
    .main.container {
        padding-bottom: 5rem;
    }

    .page-title {
        padding-top: 3rem;
    }
}

#checkout {
    #comment {
        border-width: 15px;
        border-color: @marso-gray-06;
        border: none;

        &::placeholder {
            //font-size: 12px;
            color: @marso-gray-40;
            opacity: 1;
        }
    }
}

#checkout-summary {
    .cart-summary {
        .first.attached.content {
            border-top: 1px solid @marso-brand  !important;
        }

        .attached.content {
            border-top: none !important;
            border-bottom: 2px solid @marso-gray-20  !important;
        }

        .bottom.attached.content {
            border-bottom: none !important;
        }
    }

    .sidebar {
        .form {
            .checkbox-field.field {
                .checkbox-label {
                    padding: 1rem;
                }
            }
        }
    }
}

// shipping / payment method form
.form-block {

    .shipping-method-item,
    .payment-method-item {
        background-color: @white;
        border-radius: 5px;
        margin: 1.5rem;
        padding: 2rem !important;
        border: 2px solid @white;

        .checkbox-field {
            border-bottom: 2px solid @marso-gray-10;

            .field-input.checkbox-label {
                padding: 0.5rem 0;

                .field-label {
                    font-size: 2rem;
                    padding-left: 0;

                    .price {
                        font-weight: bold;
                    }
                }
            }
        }

        .description {}

        .extra-field {
            margin-top: 3rem;

            .field-input-wrapper {
                .field-input {
                    border: 1px solid @marso-gray-10;
                }
            }
        }

        &.selected {
            border-color: @marso-red;

            .header {
                color: @marso-red;
            }
        }
    }
}

//.shipping-method-item, .payment-method-item {
//    background-color: @white;
//    border-radius: 5px;
//    margin: 1.5rem;
//    padding: 2rem !important;
//    border: 2px solid @white;
//    .item-icon {
//      .icon {
//        font-size: 5rem;
//      }
//    }
//    hr {
//      border-color: @marso-gray-10;
//      border-style: solid;
//    }
//    .header {
//      font-size: 20px;
//      .price {
//        font-weight: bold;
//      }
//    }
//    .description {
//      padding: 1rem 0;
//    }
//    &.selected {
//      border-color: @marso-red;
//      .header {
//        color: @marso-red;
//      }
//    }
