.unique-contents {
    .unique-contents-background {
        min-height: 30rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .header.hero {
        line-height: 30rem;
        margin: 0;
        font-size: 12rem;
    }

    .subcategories-menu {
        padding-top: 3rem;
        color: @white;

        .column {
            a {
                display: block;
                height: 12rem;
                background-color: @marso-gray-50;
                border-radius: 1rem;
                color: @white;
                font-weight: bold;
                padding: 1.5rem;

                img {
                    display: block;
                    max-height: 8rem;
                }

                .title {
                    display: block;
                }
            }

            &.label {
                .arrow {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 12rem;
                    font-size: 2.2rem;
                    font-weight: bold;
                    background-color: @marso-gray-80;
                    margin-right: 3rem;
                    padding-left: 2rem;
                    position: relative;
                    border-right: 0.5rem solid @marso-red;

                    &:before {
                        content: "";
                        position: absolute;
                        right: -2.5rem;
                        top: 0px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 6rem 0 6rem 2rem;
                        border-color: transparent transparent transparent @marso-red;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        right: -2rem;
                        top: 0px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 6rem 0 6rem 2rem;
                        border-color: transparent transparent transparent @marso-gray-80;
                    }
                }
            }
        }
    }

    .highlighted-offers {
        padding-top: 3rem;
    }

    .news {
        padding-top: 3rem;

        .box-title {
            margin-bottom: 0;
        }

        >.ui.grid {
            padding-top: 3rem;
        }
    }

    .page-content {
        padding-top: 3rem;
    }
}

.page-unique-content {
    .page-content {
        padding-bottom: 3rem;
    }
}
