.ui.modal.cart-exit-intent-modal {
    font-size: 2rem;
    padding: 3rem;
    border-radius: 2.5rem;

    //min-width: 50rem;
    //max-width: 60rem;
    .header {
        .h1 {
            color: @marso-red;
        }

        .subheader {
            font-weight: normal;
            font-size: 2rem;
        }
    }

    .ui.form {

        //padding-top: 3rem;
        .field-textarea {
            .field-input {
                height: auto;
                line-height: 1.75rem;
                border: 1rem solid @marso-gray-10;
            }
        }
    }

    .content {
        padding-top: 3rem;
    }

    .description {
        strong {
            color: @marso-red;
        }
    }

    .actions {
        padding-top: 3rem;
        font-size: 1.5rem;

        .button {
            margin-left: 0;
        }
    }
}

.sidebar {
    .coupon-form {
        border: 2px solid @marso-brand;
        border-radius: 0.5rem;
        padding: 3rem;
        margin-bottom: 3rem;

        .title {
            font-size: 1.8rem;
        }

        .error-label {
            left: 0 !important;
        }

        input {
            border-radius: 0.5rem;
        }

        input::placeholder {
            color: @marso-gray-30;
        }

        input:focus {
            border-top-left-radius: 0.5rem !important;
            border-bottom-left-radius: 0.5rem !important;
        }
    }
}

#donate-coupon-form {
    .alapitvany-container {
        margin: 0 auto;
        display: grid;
        grid-gap: 1rem;
    }

    .alapitvany {
        padding: 7px;
        position: relative;
        width: 100%;
        display: inline-block;
    }

    #donationHeader {
        text-transform: uppercase;
    }

    img {
        object-fit: scale-down;
        max-width: 100%;
    }

    .foundation-name {
        position: absolute;
        z-index: 1;
        bottom: 28%;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        width: fit-content;
        background-color: white;
        font-weight: bold;
        padding: 5px 10px;
        opacity: .8;
    }

    button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    @media (min-width: 600px) {
        .alapitvany-container { grid-template-columns: repeat(2, 1fr); }
    }

    @media (min-width: 900px) {
        .alapitvany-container { grid-template-columns: repeat(3, 1fr); }
    }
}

.mobile-layout {
    #donate-coupon-form {
        #donationHeader {
            padding: 0 24px;
        }
        
        #donationSubtitle {
            padding: 0 24px;
        }
    }
}