@banner-home-slider-height: (450vw/19.2);

#banners-home-slider {
    position: relative;
    // background-color: @marso-brand-blue;
    // min-height: 45rem;
    overflow: hidden;
    height: @banner-home-slider-height;

    .control-panel {
        position: absolute;
        bottom: 0;
        width: 100%;

        .previous-button,
        .next-button {
            position: absolute;
            text-shadow: 1px 0 2px @black, -1px 0 2px @black;
            bottom: @banner-home-slider-height/2 - 3rem;
            margin: 0;
        }

        .previous-button {
            left: -4rem;
        }

        .next-button {
            right: -4rem;
        }

        .direct-buttons {
            // padding-top: 1.5rem;
            padding-bottom: 1.5rem;

            .button {
                box-shadow: 0px 0px 0.4rem @black;
                background-color: @white;
                padding: 0.5rem;
                border-radius: 50%;
                font-size: 0;
                margin-left: 1rem;
                margin-right: 1rem;

                span {
                    display: block;
                    padding: 0.5rem;
                    background-color: transparent;
                    border-radius: 0.5rem;
                }

                &.active {
                    span {
                        background-color: @black;
                    }
                }
            }
        }
    }

    .banner {
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        background-position: center top;
        overflow: hidden;

        img {
            width: 100%;
            height: @banner-home-slider-height;
        }

        .content-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        &.hidden {
            display: none;
        }

        .text {
            padding-top: @banner-home-slider-height / 15 !important;
            height: @banner-home-slider-height;

            .header,
            .subheader,
            .description,
            .button {
                margin: 0 (@banner-home-slider-height / 15) (@banner-home-slider-height / 15) (@banner-home-slider-height / 15);
            }

            .header {
                min-height: @banner-home-slider-height / 8;
                font-style: italic;
                color: @white;
                font-size: @banner-home-slider-height / 8;
                text-shadow: #000 1px 1px 0
            }

            .subheader {
                min-height: @banner-home-slider-height / 15;
                display: inline-block;
                font-weight: normal;
                font-size: @banner-home-slider-height / 15;
                line-height: @banner-home-slider-height / 15;
                box-shadow: 0 0 10px rgba(0, 0, 0, .175);
                color: @white;
                background-color: @marso-banner;
                padding: 1rem 2rem;
            }

            .description {
                // min-height: 6rem;
                max-width: 80%;
                color: @white;
                font-size: @banner-home-slider-height / 17;
                line-height: @banner-home-slider-height / 17;
                text-shadow: #000 1px 1px 0;
            }

            .button {
                box-shadow: 0 0 10px rgba(0, 0, 0, .175);
                color: @marso-banner-button-text;
                border: 2px solid @marso-banner;
                border-radius: 0.5rem;
                font-size: @banner-home-slider-height / 20;
                background-color: @marso-banner-button-highlight;

                .icon {
                    font-size: @banner-home-slider-height / 15;
                    border-radius: 0;
                    opacity: 1;
                    background-color: @marso-banner;
                }
            }
        }
    }

    // color style red
    .banner.style-red {
        .text {
            .subheader {
                background-color: @marso-banner-red;
            }

            .button {
                color: @marso-banner-red-button-text;
                border-color: @marso-banner-red;
                background-color: @marso-banner-red-button-highlight;

                .icon {
                    background-color: @marso-banner-red;
                }
            }
        }
    }

    // color style green
    .banner.style-green {
        .text {
            .subheader {
                background-color: @marso-banner-green;
            }

            .button {
                color: @marso-banner-green-button-text;
                border-color: @marso-banner-green;
                background-color: @marso-banner-green-button-highlight;

                .icon {
                    background-color: @marso-banner-green;
                }
            }
        }
    }

    // color style blue
    .banner.style-blue {
        .text {
            .subheader {
                background-color: @marso-banner-blue;
            }

            .button {
                color: @marso-banner-blue-button-text;
                border-color: @marso-banner-blue;
                background-color: @marso-banner-blue-button-highlight;

                .icon {
                    background-color: @marso-banner-blue;
                }
            }
        }
    }

    // color style black
    .banner.style-black {
        .text {
            .subheader {
                background-color: @marso-banner-black;
            }

            .button {
                color: @marso-banner-black-button-text;
                border-color: @marso-banner-black;
                background-color: @marso-banner-black-button-highlight;

                .icon {
                    background-color: @marso-banner-black;
                }
            }
        }
    }
}

.fake-product-banner {
    height: 100%;
    width: 100%;
    .banner.url {
        display: grid;
        width: 100%;
        height: 100%;
        align-content: center;
    }
    .banner.image {
        width: 100%;
    }
}

.in-news-banner {
    margin-bottom: 2rem;
}

#banners-bottom-popup {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 10000;

    >.wrapper {
        padding: 2rem 0;
        position: absolute;
        bottom: -500px;
        left: 0;
        right: 0;
        background-color: @white;
        transition: all 300ms ease-in;

        &.show {
            bottom: 0;
        }

        >.ui.container {
            max-width: 600px !important;
        }

        .ui.unstackable.items {
            >.item {
                align-items: center;
            }
        }

        .main-image {
            padding: 0 2rem 0 0;
        }

        .cab-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            >a {
                display: block;

                >img {
                    max-height: 8rem;
                }
            }

            &.no-os-info {
                >a {
                    >img {
                        max-height: 5.5rem;
                    }
                }
            }
        }

        .cancel-button {
            margin: 2rem 0 4rem 0;
        }
    }
}

#banners-home-secondary {
    padding-top: 6rem;
    margin: auto;
    max-width: 970px;

    .banner.image {
        width: 100%;
        text-align: center;
        display: block;
    }
}
