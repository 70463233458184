/* cart
*/
.page-cart {
    .page-title {
        padding-top: 3rem;
    }

    .services-box {
        padding-top: 9rem;
        padding-bottom: 3rem;

        >.header {
            font-size: 2.5rem;
        }
    }

    .cetelem-box {
        margin-top: 3rem;
    }
}

#cart {
    .cart-products {
        .segment:nth-last-child(-1) {
            border-bottom: none;
        }

        .segment:last-child {
            border-bottom: none;
        }

        .segment {
            background-color: @marso-gray-06;
            border-bottom: 1px solid @marso-gray-50;
            position: relative;
        }

        .product {
            padding: 3rem;

            .remove-button {
                padding: 0.5rem;
                height: 4rem;
                width: 4rem;
                background-color: @marso-gray-30;

                .icon {
                    font-size: 2.5rem;
                    margin-right: 0;
                }
            }

            .product-image-wrapper {
                background-color: @white;
                text-align: center;
            }

            .product-price-wrapper {
                text-align: right;
                font-size: 2.5rem;
                line-height: 2.5rem;

                .price-label {
                    font-size: 1.5rem;
                    line-height: 1.5rem;
                    padding-bottom: 0.5rem;
                }

                .sub-total-price {
                    font-weight: bold;
                }

                .quantity-form {
                    margin: 0 -1rem;
                }

                .quantity-stepper-wrapper {
                    padding-right: 0;
                }

                .quantity-stepper {
                    max-width: 10rem;
                    position: relative;
                    font-size: 1.4rem;
                    height: 4rem;

                    .quantity {
                        height: 4rem;
                        border: none;
                        text-align: right;
                        padding-right: 5.5rem;
                        font-weight: bold;
                    }

                    .quantity-label {
                        position: absolute;
                        top: 0;
                        right: 0;
                        line-height: 4rem;
                        padding-right: 3rem;

                        .buttons {
                            width: 20px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            text-align: center;

                            .button {
                                padding: 0;
                                height: 2rem;
                                line-height: 2rem;
                                font-size: 2rem;

                                .icon {
                                    margin: auto;
                                    display: block;
                                    text-align: center;
                                    height: inherit;
                                    width: inherit;
                                }

                                &.quantity-up {
                                    background-color: @marso-gray-20;
                                    border-top-left-radius: 0;
                                    text-align: center;
                                }

                                &.quantity-down {
                                    background-color: @marso-gray-30;
                                    border-bottom-left-radius: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .question-box {
            position: relative;
            background-color: @marso-green;
            color: @white;

            &:before {
                content: " ";
                position: absolute;
                height: 0;
                width: 0;
                left: 8.5rem;
                top: -1.5rem;
                border-top: none;
                border-right: 1.5rem solid transparent;
                border-left: 1.5rem solid transparent;
                border-bottom: 1.5rem solid @marso-green;
            }

            .image {
                position: absolute;
                left: 3rem;
                top: 50%;
                transform: translateY(-50%);
                height: 7rem;
                line-height: 7rem;
                width: 7rem;
                display: block;

                .icon {
                    height: 7rem;
                    font-size: 6.3rem;
                    line-height: 7rem;
                    text-align: left;
                }
            }

            .yes-or-no {
                position: absolute;
                right: 3rem;
                top: 50%;
                transform: translateY(-50%);
                width: 16rem;
                height: 4rem;
                background-color: @white;
                border-radius: 0.5rem;

                .radio-field {
                    height: 100%;
                    width: 100%;

                    .radio-label {
                        height: inherit;
                        width: inherit;
                        padding: 0.5rem;

                        .ui.labeled.button {
                            width: 100%;
                            margin-right: 0;

                            .label,
                            .ui.button {
                                display: inline-block;
                                width: 50%;
                                height: 3rem;
                                border: none;
                                padding: 0.5rem;
                                line-height: 2rem;
                                font-size: 2rem;
                            }

                            .label {
                                color: @marso-gray-30;
                            }
                        }

                        .on,
                        .off {
                            height: inherit;
                            width: inherit;

                            .icons {
                                .icon {
                                    font-size: 3rem;
                                }
                            }
                        }
                    }
                }
            }

            .question {
                font-size: 1.8rem;
                margin-left: 9rem;
                margin-right: 19rem;
                padding: 1.5rem 1rem 0 1rem;
                height: 7rem;
                border-top-right-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
            }
        }

        .warning-box {
            position: relative;
            background-color: @marso-orange;
            color: @white;

            &.current-item:before {
                content: " ";
                position: absolute;
                height: 0;
                width: 0;
                left: 8.5rem;
                top: -1.5rem;
                border-top: none;
                border-right: 1.5rem solid transparent;
                border-left: 1.5rem solid transparent;
                border-bottom: 1.5rem solid @marso-orange;
            }

            .image {
                position: absolute;
                left: 3rem;
                top: 50%;
                transform: translateY(-50%);
                height: 7rem;
                line-height: 7rem;
                width: 7rem;
                display: block;

                .icon {
                    height: 7rem;
                    font-size: 6.3rem;
                    line-height: 7rem;
                    text-align: left;
                }
            }

            .warning {
                //font-size: 1.8rem;
                margin-left: 9rem;
                //margin-right: 19rem;
                padding: 1.5rem 1rem 1rem 1rem;
                //height: 7rem;
                border-top-right-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;

                p {
                    margin: 0 0 0.2rem;
                }

                a {
                    font-weight: bold;
                    //color: @marso-blue;
                }
            }
        }
    }
}

/* cart sidebar
*/
.sidebar {
    .order-summary.box {
        border: 2px solid @marso-brand;
        border-radius: 0.5rem;
        padding: 3rem;
        margin-bottom: 3rem;

        .title {
            font-size: 2rem;
        }

        .list {
            .item {
                line-height: 3rem;
                padding-bottom: 1rem;

                &>.label {
                    font-size: 2rem;
                }

                .price {
                    font-size: 2rem;
                    font-weight: bold;
                }

                .right.floated {
                    margin-left: 1rem;
                }
            }

            .item.shipping {
                .label {
                    //font-size: 1.75rem;
                    //line-height: 2rem;
                    //padding: 0;
                }

                .value {
                    //font-size: 1.75rem;
                    //line-height: 2rem;

                }

                .value.free {
                    background-color: @marso-green;
                    color: @white;
                    font-weight: bold;
                    border-radius: 0.5rem;
                    padding: 0.5rem 1rem;
                }
            }
        }

        .total-price.item {
            border-top: 2px solid @marso-gray-10;
            padding-top: 1.5rem;
        }
    }

    &>button,
    &>form>.sidebar-button.ui.button.fluid.right.labeled.icon,
    #checkout-goto-next-step-button {

        //font-size: 20px !important;
        text-align: left !important;
        padding: 2rem 5rem 2rem 2rem !important;
        line-height: 2.2rem !important;

        .icon {
            width: 5rem;
        }
    }
}
