.page-booking {
    .main.container {
        padding-top: 6rem;
        padding-bottom: 2rem;
    }
}

#service-booking {
    #booking-form {}

    #booking-choose-garage {
        .content.horizontal-scroll {
            padding-left: 0;
            padding-right: 0;
        }

        .garage {
            background-color: @white;
            box-shadow: none;
            max-width: 25rem;
            margin: 1rem;

            .content:first-child {
                padding-top: 1em;
            }

            .content:last-child {
                padding-bottom: 1em;
            }

            .content {
                border-top: none;
                padding: 0.5em 1em;
            }

            .content.upperlined {
                margin: 0 1em;
                padding: 0.5em 0;
                border-top: 1px solid @marso-gray-10;
            }

            .content.icons {
                .icon {
                    background-color: @marso-gray-80  !important;
                }
            }

            .image.logo {
                padding: 1em;
                height: 4rem;
                background-color: @white;

                img {
                    max-height: 2.7rem;
                    width: auto;
                }

                &.partner {
                    img {
                        max-height: 2.6rem;
                    }
                }
            }

            .name {
                text-transform: uppercase;
                font-size: 1.6rem;

                .distance {
                    font-weight: bold;
                }
            }

            .image {}

            .opening {
                .list {
                    margin-top: 0;
                }
            }

            .go-to-map {
                a {
                    color: @marso-brand;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    padding: 0.5em 0;
                    display: block;
                    border-top: 1px solid @marso-gray-10;
                    border-bottom: 1px solid @marso-gray-10;

                    .icon {
                        font-size: 2.5rem;
                    }
                }
            }

            .content.services-list {
                max-height: 140px;
                overflow: hidden;
                position: relative;

                strong {
                    font-size: 1.3rem;
                }

                ul {
                    margin-top: 0.5rem;
                    font-size: 1.3rem;
                }

                .more-button {
                    background-color: @white  !important;
                    padding: 0.25rem 1rem;
                    font-weight: normal;
                    font-size: 1.3rem;
                    text-decoration: underline;
                    text-align: left;
                    position: absolute;
                    bottom: 0;

                    i {
                        text-decoration: none;
                    }
                }

                &.opened {
                    max-height: none;

                    .more-button {
                        position: relative;
                    }
                }
            }

            .selected-button {
                .button {
                    color: @marso-brand;
                    background-color: @white;
                    border: 1px solid @marso-brand;
                    text-transform: uppercase;
                    text-align: left;
                    font-size: 1.7rem;
                    padding: 0.75em 0.5em !important;
                }
            }
        }

        .garage.selected {
            box-shadow: 0px 2px 0px 0px @marso-brand, 0px 0px 0px 2px @marso-brand;

            .selected-button {
                .button {
                    color: @white;
                    background-color: @marso-brand;
                    border: none;
                }
            }
        }
    }

    #booking-choose-service {
        .content.horizontal-scroll {
            padding-left: 0;
            padding-right: 0;
        }

        .service {
            width: 100%;
            background-color: @white;
            box-shadow: none;
            max-width: 30rem;
            margin: 1rem;

            .content:first-child {
                padding-top: 1em;
            }

            .content:last-child {
                padding-bottom: 1em;
            }

            .content {
                border-top: none;
                padding: 0.5em 1em;
            }

            .name {
                background-color: @marso-gray-30;
                color: @white;
                text-transform: uppercase;
                font-size: 2rem;
                min-height: 10rem;
                vertical-align: middle;

                &.with-image {
                    img {
                        width: 7rem;
                        float: left;
                        margin-right: 1.5rem;
                    }

                    .value {
                        strong {
                            display: block;
                            font-size: 3rem;
                            line-height: 3rem;
                        }

                        small {
                            line-height: 2rem;
                        }
                    }
                }
            }

            .subname {
                font-style: italic;
                font-size: 1.4rem;
            }
        }

        .price {
            .value {
                text-align: center;
                font-size: 3rem;
                font-weight: bold;
                padding: 0.5em 0;
                display: block;
                border-top: 1px solid @marso-gray-10;
                border-bottom: 1px solid @marso-gray-10;
            }

            .value:last-child {
                border-top: none;
            }

            .value.highlighted {
                color: @marso-red;
            }

            .value.original {
                text-decoration: line-through;
            }
        }

        .description {
            .value {
                ul {
                    padding-left: 2rem;
                }
            }
        }

        .selected-button {
            .button {
                color: @marso-brand;
                background-color: @white;
                border: 1px solid @marso-brand;
                text-transform: uppercase;
                text-align: left;
                font-size: 1.7rem;
                padding: 0.75em 0.5em !important;
            }
        }

        .service.selected {
            box-shadow: 0px 2px 0px 0px @marso-brand, 0px 0px 0px 2px @marso-brand;

            .selected-button {
                .button {
                    color: @white;
                    background-color: @marso-brand;
                    border: none;
                }
            }
        }
    }

    #booking-choose-datetime {

        .datetimes-explanation {
            margin-top: 3rem;
            box-shadow: none;
            border: none;

            .column:first-child {
                font-size: 2.5rem;
            }

            .column {
                .icon.grey {
                    color: @marso-gray-30  !important;
                }
            }
        }

        #date-selector {
            .segment {
                border: none;
            }

            .header {
                background-color: @marso-gray-50;
                color: @white;
                height: 6rem;
                padding: 1rem;
                line-height: 4rem;
                font-size: 2rem;
                border: none;
                margin: 0;

                .button {
                    font-size: 2rem;
                    padding: 1.2rem 1rem 1rem;
                    height: 4rem;
                }
            }

            .days {
                .day {
                    padding: 1rem 0.5rem;

                    button {
                        outline: none;
                        font-size: 1.4rem;
                        line-height: 1.4em;
                        padding: 1.5rem 0 !important;
                    }
                }
            }
        }

        #time-selector {
            margin-top: 3rem;

            .time {
                .field {
                    cursor: pointer;
                    text-align: center;
                    border-radius: 0.5rem;
                    background-color: @white;
                    padding: 2rem 1rem;
                    height: auto;

                    label {
                        cursor: pointer;
                        display: block;

                        .radio-button {
                            .icon {
                                margin: 0;
                            }

                            .selected.icon {
                                display: none;
                            }
                        }
                    }

                    .date-value {
                        display: block;
                        font-weight: normal;
                        padding-top: 1rem;
                        font-size: 2.5rem;
                        line-height: 3rem;
                    }

                    .time-value {
                        display: block;
                        font-size: 3.6rem;
                        line-height: 3.5rem;
                        font-weight: bold;
                    }
                }
            }

            .time.selected {
                .field {
                    border: 2px solid @marso-brand;

                    .radio-button {
                        .selected.icon {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    #booking-preview {
        background-color: @marso-green;
        color: @white;
        border-radius: 0.5rem;

        .marso-icon-tyre_date {
            font-size: 17rem;
        }

        .list {
            margin: 1rem;

            .item:last-child {
                padding-bottom: 2rem;
                border-bottom: solid 1px @marso-gray-06;
            }

            .item {
                border-color: @marso-gray-06;

                >.icon {
                    font-size: 3.5rem;
                }

                .header {
                    color: @white;
                    font-size: 3.6rem;
                    line-height: 4rem;
                }
            }
        }
    }

    #booking-button-block {
        background: none;

        #booking-button {
            min-height: 8rem;
            text-align: left;
            font-size: 2.8rem;
            padding: 2rem 8rem 2rem 2rem !important;

            .icon {
                width: 8rem;
            }
        }

        #notice {
            border-width: 1.5rem;
            border-color: @marso-gray-10;

            &::placeholder {
                color: @marso-gray-40;
                opacity: 1;
            }
        }
    }
}

#tyre-hotel {
    .question {
        .message {
            box-shadow: none;
            margin-bottom: 1rem;
            background-color: #fff;
        }
    }
    .segment {

    }
    .segment.sip {
        min-height: 10rem;
    }
}

#no-license-plate-number-error {
    top: -100%;
    text-align: center;
}
