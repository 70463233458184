
#dot-tyres-products-section-wrapper {
    min-height: 10rem;
}

#dot-tyres-modal {
    background-color: transparent;
    border-radius: 0.5rem;
    > .content {
        padding: 0;
        border-radius: 0.5rem;
        background-color: transparent;
        border: 1px solid @marso-gray-80;
        .form-block {
            margin-bottom: 0;
        }
    }
    .field-input:disabled {
        color: @marso-gray-85;
        font-weight: bold;
        opacity: 1;
    }
    .quantity-stepper-wrapper {
        padding-right: 0;
    }

    .quantity-stepper {
        position: relative;
        font-size: 1.4rem;
        height: 5rem;

        .quantity {
            height: 5rem;
            border: none;
            text-align: right;
            padding: 0.5rem;
            padding-right: 5.5rem;
            font-weight: bold;
            &:focus {
                border-radius: 0.5rem;
            }
        }

        .quantity-label {
            position: absolute;
            top: 0;
            right: 0;
            line-height: 5rem;
            padding-right: 3rem;

            .buttons {
                width: 20px;
                position: absolute;
                top: 0;
                right: 0;
                text-align: center;

                .button {
                    padding: 0;
                    height: 2rem;
                    line-height: 2.5rem;
                    font-size: 2.5rem;

                    .icon {
                        margin: auto;
                        display: block;
                        text-align: center;
                        height: inherit;
                        width: inherit;
                    }

                    &.quantity-up {
                        background-color: @marso-gray-20;
                        border-top-left-radius: 0;
                        text-align: center;
                    }

                    &.quantity-down {
                        background-color: @marso-gray-30;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }
    }
}

