#contact-page {
    .data-panel {
        padding-top: 2rem;
        padding-bottom: 4rem;
        font-size: 2.1rem;

        .item {
            height: 5rem;
            line-height: 3rem;
            padding: 1rem 0 !important;
            border-top: 2px solid @marso-gray-06;

            .icon {
                font-size: 3rem;
                margin-top: -0.7rem;
            }

            .content {
                padding-left: 1rem;
            }

            .icon-content {
                float: left;
                padding-left: 0;
                padding-right: 1rem;
            }
        }

        .item:last-child {
            border-bottom: 2px solid @marso-gray-06;
        }

        .company-name {
            font-weight: bold;
        }

        .phone {
            a {
                color: @marso-red;
            }
        }

        .email {
            a {
                color: @marso-red;
            }
        }
    }

    .service-site-search-panel {
        background-color: @marso-gray-10;
        padding: 2.5rem 0 2.5rem;

        .question {
            h1 {
                font-size: 4.2rem;
            }
        }

        .answer {
            .button {
                font-size: 2.5rem;
                line-height: 4rem;
                padding: 2rem 1rem 2rem 2rem !important;
            }
        }
    }

    .form-block {
        .close-button {
            display: none;
        }
    }
}

.contact-form-panel {
    background-color: @marso-red;
    padding-bottom: 2rem;
    padding-top: 4rem;

    .description {
        font-size: 1.6rem;
    }

    .callcenter {
        margin-top: 2rem;
        border-top: 1px solid @white;
        border-bottom: 1px solid @white;

        .item.address {
            font-weight: bold;

            .icon {
                font-size: 4.5rem;
            }

            &:first-child {
                padding-top: 1rem !important;
            }

            &:last-child {
                padding-bottom: 1rem !important;
            }

            .content {
                font-size: 1.8rem;
            }

            .icon-content {
                float: left;
                padding-right: 1rem;
            }
        }
    }

    .question {
        color: @white;

        h2 {
            margin-bottom: 2rem;
            font-size: 3.75rem;
            line-height: 3.75rem;
            font-weight: normal !important;
        }
    }
}

#contact-modal {
    .contact-form-panel {
        padding: 4rem 3rem 2rem 3rem;
    }
}

#contact-form {
    .field-label-wrapper {
        .field-label {
            background-color: @marso-gray-06;
            color: @marso-gray-85  !important;
            font-weight: normal;
            text-transform: none;
        }
    }
}
