.services {
    .ui.container {
        .content {
            padding-bottom: 3rem;
        }
    }

    .services-background {
        position: relative;
        overflow: hidden;
        min-height: 30rem;
        margin-bottom: 3rem;
        background-color: @white;
        background-position: center center;
        background-repeat: no-repeat;

        picture {
            position: absolute;
            margin: auto 50vw;
            transform: translateX(-50%);
        }

        > .container {
            position: relative;
        }
    }

    .header.hero {
        line-height: 30rem;
    }

    .ui.card.content-service {
        .continue {
            background-color: @marso-gray-80;
            color: @white;
            font-weight: bold;
            font-size: 1.8rem;
            padding-top: 1rem;
        }
    }

    .ui.image.service {
        background-color: @marso-gray-06;
        position: absolute;
        top: -75px;
    }

    article {
        position: relative;

        .header {
            min-height: 15rem;
            max-height: 17rem;

            .title {
                font-size: 4rem;
            }
        }
    }

    .offer-request-button-wrapper {
        margin-top: 3rem;
        .offer-request-button {
            margin-top: 3rem;
        }
    }

    .sidebar {
        border-left: 1px solid @marso-gray-20;
    }
}

#marso-services-table {
    border-radius: 0;
    border: none;

    thead {
        th {
            background-color: @marso-gray-80;
            color: @white;
        }
    }

    tr {
        td {
            border-top: 0;
        }
    }

    &.striped.table>tr:nth-child(2n),
    &.striped.table tbody tr:nth-child(2n) {
        background-color: @marso-gray-10;
    }
}

.services-box {
    >.message {
        box-shadow: none;
        border-radius: 10px;
        background-color: @marso-gray-10;
        margin: 3rem 0 !important;
    }

    .content-service.teaser {
        >.image {
            position: relative;

            .image {
                margin: auto;
            }

            .name {
                width: 100%;
                position: absolute;
                bottom: 10px;
                font-weight: bold;
                color: @white;
                text-shadow: 1px 1px 2px fade(@marso-gray-85, 95%),
                    -1px -1px 2px fade(@marso-gray-85, 90%);
            }

            border-bottom: 4px solid @marso-gray-80;
        }

        .description-block {
            background-color: @marso-gray-10;

            .marso-icon-info {
                color: @marso-gray-30;
                margin-right: 0;
            }

            .description {
                background-color: @white;
                padding: 0.5rem !important;
                font-size: 1.5rem;

                .text {
                    max-height: 10rem;
                    overflow: hidden;
                }
            }

            .price {
                font-weight: bold;
                font-size: 2.75rem;
                line-height: 2.4rem;
                padding-top: 10px;
            }
        }

        .cart-button {
            border-top: none;
            padding: 1rem 0;

            .service-request-form {
                width: 100%;
                padding: 0;
                display: block;

                .add-to-cart-button {
                    padding: 1.3rem 1rem;
                }
            }
        }
    }
}
