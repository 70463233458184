.page-wheel-wizard {
    .wheel-wizard {
        padding-top: 3rem;

        .steps-panel {}

        .form-panel {
            padding-top: 3rem;
        }

        .buttons-panel {}
    }

    .wheel-wizard-steps {
        .step {
            height: 33rem;
            display: block;
            border: 0.5rem solid @marso-gray-10;
            border-radius: 1rem;
            text-align: center;
            color: @marso-gray-20;
            padding: 1rem;

            .icon-wrapper {
                display: block;
                padding-top: 3rem;

                .icon.massive {
                    font-size: 14rem !important;
                }
            }

            .label {
                display: block;
                font-size: 2rem;
                font-weight: bold;
                padding-top: 2rem;
            }
        }

        .step.completed {
            padding: 0;

            .image-wrapper {
                .image {
                    margin: auto;
                    border-top-left-radius: 0.5rem;
                    border-top-right-radius: 0.5rem;
                }
            }

            .label {
                font-weight: normal;
                padding: 1rem 1rem 0 1rem;
                font-size: 1.75rem;
                color: @marso-gray-85;
                text-align: left;
            }

            .name {
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
            }

            .sizes {
                font-size: 1.5rem;
                color: @marso-gray-60;
                text-align: center;
            }

            .price {
                text-align: center;
                font-size: 2rem;
                line-height: 2.5rem;
                padding-top: 0.5rem;
                font-weight: bold;
            }

            .sub-label {
                //display: none;
                display: block;
                padding: 0.5rem 1rem 0 1rem;
                text-align: left;
                text-transform: uppercase;
                //font-weight: bold;
                font-size: 1.5rem;
                color: @marso-gray-60;
            }
        }

        .step.active {
            border-color: @marso-red;
            position: relative;

            .label {
                color: @marso-red;
            }

            .icon-wrapper .icon {
                color: @marso-red;
            }

            &:after {
                position: absolute;
                bottom: -1.8rem;
                left: 50%;
                content: " ";
                width: 0;
                height: 0;
                border-style: solid;
                margin-left: -3rem;
                border-width: 1.5rem 3rem 0 3rem;
                border-color: @marso-red transparent transparent transparent;
            }
        }

        .step.choose-rim,
        .step.choose-tyre {
            padding-top: 2rem;
        }
    }

    .wheel-wizard-buttons {
        .buttons {
            justify-content: center;
        }
    }

    .wheel-wizard {

        .car-form .select-field .dropdown.icon {
            padding: 1rem !important;
            line-height: 2.5rem;
            top: 2.5rem;
            right: 2.5rem;
        }
    }

    #choose-rim-form-rims {
        .rim {
            text-align: center;
            font-size: 2rem;
            cursor: pointer;

            .inner {
                padding: 1rem;
                border: 0.4rem solid @white;
            }

            .name {}

            .sizes {
                padding-top: 1rem;
                font-size: 1.75rem;
                white-space: nowrap;
            }

            .price {
                font-weight: bold;
                padding-top: 1rem;
            }
        }

        .rim.transition.hidden {
            display: none;
        }

        .rim.selected .inner {
            border: 0.4rem solid @marso-red;
        }
    }


    #step-start {
        padding-top: 0;

        .header-wrapper {
            color: @white;

            .description-wrapper {
                position: relative;

                .description {
                    position: absolute;
                    bottom: 0;
                    padding: 3rem 3rem 18rem 3rem;
                    font-size: 1.75rem;

                    .header {
                        font-size: 6rem;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .steps-wrapper {
            .steps {
                position: relative;
                top: -10rem;

                .wheel-wizard-steps {
                    .step {
                        background-color: @white;
                    }
                }
            }
        }

        .wheel-wizard-buttons {
            padding-bottom: 3rem;

            .button {
                font-size: 4.5rem;
                padding: 2rem 2rem;
                border-radius: 1rem;
                text-align: left;
            }
        }
    }

    #step-choose-tyre {
        #choose-tyre-form {
            .tyre.transition.hidden {
                display: none;
            }

            .tyre.selected .product {
                border: 0.4rem solid @marso-red;
            }

            .select-tyre-button {

                .product {
                    border-radius: 0;
                    border: 0.4rem solid @white;
                }

                .product.teaser:hover {
                    box-shadow: none !important;
                }
            }
        }
    }

    .wheel-wizard {
        #add-to-cart-form {
            border: 0.5rem solid @marso-gray-10;
            border-radius: 1rem;
            padding: 3rem;
        }
    }

    .completed-form {
        .header {
            font-size: 3rem;
            color: @marso-red;
            border-bottom: 0.2rem solid @marso-light-red;
            padding-bottom: 2rem !important;
        }

        .coupon {
            .price {
                color: @marso-green;
                font-weight: bold;
            }
        }
    }
}
