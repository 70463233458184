#page-header {
    background-color: @white;
    will-change: auto;
    box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
    top: -200px;
    transition: top 0.5s;
}

.fixed-header #page-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
}

/* header top */
.header-top {
    .company-navbar {
        height: 3rem;
        margin: 0 !important;
        padding: 0;

        .item {
            height: 3rem;
            font-size: 1.2rem;
            padding: 0.4rem 1rem;
            font-weight: bold;
            color: @marso-gray-80;
            text-transform: uppercase;

            &.phone {
                padding-left: 0.2rem;
            }
            &.phone:hover {
                color: @marso-brand;
            }

            &.left {
                float: left;
            }

            &.right {
                float: right;
            }
        }

    }
}

/* simple header */
.header-simple {
    background-color: @marso-brand;

    >.container {
        >.grid {
            height: 6rem;
            width: inherit;

            >.row {
                height: inherit;
            }
        }
    }

    .back-to-site {
        padding: 0;

        >a {
            color: @white;
            height: 4rem;

            .column {
                padding: 0 !important;
            }

            .label {
                text-align: right;
                font-size: 1.2rem;
                text-transform: uppercase;
                font-weight: bold;
                line-height: 1.4rem;
            }

            >.icon {
                padding-left: 0.5rem;
                line-height: 5rem;
                font-size: 1.8rem;
            }
        }
    }
}

.process {
    .column:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;

        &:before,
        &:after {
            content: none;
        }
    }

    .column:last-child {
        border-top-right-radius: 45px;
        border-bottom-right-radius: 45px;
    }

    >.column {
        text-align: center;
        background-color: @marso-dark-red;
        color: @white;
        padding: 0 20px 0 10px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -10px;
            border-top: none;
            border-right: 10px solid @marso-dark-red;
            border-left: none;
            border-bottom: 20px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -10px;
            border-top: 20px solid transparent;
            border-right: 10px solid @marso-dark-red;
            border-left: none;
            border-bottom: none;
        }
    }

    >.column.active {
        background-color: @white;
        color: @marso-brand;

        &:before,
        &:after {
            border-right-color: @white;
        }
    }

    >.column.success {
        color: @marso-gray-30;

        .icon {
            display: inline-block !important;
        }
    }
}

.header-center-wrapper {
    .header-center {
        max-height: 7rem;
        background-color: @marso-red;
        box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);

        .container {
            height: 7rem;
            //padding: 0.5rem 0;
        }
    }

    .main {
        .row {
            .search-box-wrapper.column {
                padding-left: 1rem !important;
                padding-right: 1rem !important;
            }
        }
    }
}

.main-menu-button-wrapper {
    .button {
        height: 7rem;
        padding: 0.5rem 0 !important;
        width: 6rem;
        border-radius: 0;
        background-color: @marso-red  !important;

        &:hover,
        &.opened {
            background-color: @marso-blue  !important;

            .inside-container {
                border-color: @marso-light-blue;
            }
        }

        .inside-container {
            display: block;
            height: 6rem;
            padding: 1.8rem 0 1.7rem 0;
            line-height: 2.5rem;
            border-left: 1px solid @marso-light-red;
            border-right: 1px solid @marso-light-red;
        }

        .icon {
            font-size: 2.5rem;
        }
    }
}

#search-box-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @black;
    opacity: 0.8;
    z-index: 90;
    display: none;

    //box-shadow: 0px 0px 10px 5px fade(@marso-gray-80, 50%);
    &.open {
        display: block;
    }
}

#search-box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    z-index: 100;

    #search-category-dropdown {
        flex: 4;
        margin-right: 3px;
        box-shadow: 0px 4px 0px 0px fade(@marso-gray-85, 30%);

        &:after {
            content: " ";
            position: absolute;
            height: 0px;
            width: 0px;
            left: -7px;
            top: 11px;
            border-top: 8px solid transparent;
            border-right: 8px solid @white;
            border-left: none;
            border-bottom: 8px solid transparent;
        }
    }

    #search-car-dropdown {
        flex: 3;
        margin-right: 3px;
        box-shadow: 0px 4px 0px 0px fade(@marso-gray-85, 30%);
    }

    #free-hand-search-wrapper {
        position: relative;
        flex: 8;
        display: flex;
        box-shadow: 0px 4px 0px 0px fade(@marso-gray-85, 30%);
        line-height: 40px;
        float: right;
        z-index: 99;

        #free-hand-search-tags {
            flex: 1 0 auto;
            background-color: @white;
            display: inline-block;
            line-height: 37px;

            .ui.label.tag {
                position: relative;
                padding: 10px 5px 10px 8px;
                font-size: 10px;
                line-height: 10px;
                border-radius: 2px;
                margin-left: 12px;
                cursor: pointer;

                &:before {
                    transform: none;
                    background-color: transparent;
                    position: absolute;
                    content: "";
                    top: 0;
                    left: -10px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 15px 10px 15px 0;
                    border-color: transparent @marso-gray-10 transparent transparent;
                }
            }
        }

        #free-hand-search {
            flex: 0 1 100%;
            min-width: 0;
            display: inline-block;
            border: none;
            padding-left: 10px;

            &:focus {
                outline: none;
            }

            &::placeholder {
                font-size: 18px;
                color: @marso-gray-50;
                opacity: 1;
            }
        }

        #search-info {
            display: none;
            position: absolute;
            top: 40px;
            left: 0;
            background-color: @white;
            line-height: 20px;
            z-index: 100;
            width: 100%;
            box-shadow: 0px 2px 3px 0px fade(@marso-gray-85, 15%);
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;

            .results {
                .item {
                    cursor: pointer;

                    &.selected {
                        background-color: @marso-gray-10;
                    }
                }
            }

            .items {
                padding-bottom: 1rem;
                margin-bottom: 0;

                .item {
                    font-size: 1.5rem;
                    height: 3rem;
                    line-height: 2rem;
                    padding: 0.5rem 2rem;
                    color: @marso-gray-85;
                    margin: 0;

                    .icon {
                        color: @marso-gray-30;
                    }

                    .content {
                        font-size: 1.5rem;
                    }

                    &.header {
                        height: 4rem;
                        border-top: 1px solid @marso-gray-10;
                        border-bottom: 1px solid @marso-gray-10;
                        font-weight: bold;
                        margin-bottom: 1rem;

                        .content {
                            font-size: 1.75rem;
                        }
                    }
                }

                .detailed-search {
                    border-top: 1px solid @marso-gray-10;
                    background-color: @marso-red;
                    color: @white;
                    font-weight: bold;
                    text-align: right;
                }
            }

            .mass-tags {
                .header {
                    height: 4rem;
                    line-height: 3rem;
                    border-top: 1px solid @marso-gray-10;
                    border-bottom: 1px solid @marso-gray-10;
                    font-weight: bold;
                    font-size: 1.75rem;
                    margin-bottom: 1rem;
                    padding: 0.5rem 2rem;
                }

                .tags {
                    padding: 1rem;

                    .add-tag-button {
                        padding: 0.5rem 1rem;
                        font-size: 1.5rem;
                    }

                    .add-tag-button:hover {
                        color: @marso-gray-80;
                    }
                }

            }
        }

        #free-hand-search.focus~#search-info {
            display: block;
        }
    }

    #free-hand-search-button {
        flex: 1;
        box-shadow: 0px 4px 0px 0px fade(@marso-gray-85, 30%);
        border-radius: 0.5rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: 40px;
        min-width: 6rem;
        float: right;
        line-height: 2rem;
        padding: 0;
        font-size: 1.5rem;
        z-index: 99;

        .icon {
            font-size: 2.5rem;
            vertical-align: text-bottom;
            margin: 0;
            padding: 0;
            opacity: 1;
        }

        .waiting.icon {
            display: none;
        }

        &.waiting {
            .icon {
                display: none;
            }

            .waiting.icon {
                display: inline-block;
                animation: icon-loading 2s ease-out infinite;
            }
        }
    }

    .ui.dropdown {
        min-height: 40px;
        height: 40px;
        line-height: 20px;
        padding: 0 0 0 10px;
        margin: 0;
        z-index: 99;
        display: inline-block;
        border: 0;
        background-color: @white;

        .icon {
            font-size: 1.2rem;
        }

        .title {
            position: relative;
            top: 6px;
            color: @marso-gray-80;
            font-size: 1.6rem;

            &.no-label {
                position: static !important;
            }
        }

        .label {
            display: block;
            font-size: 10px;
            vertical-align: bottom;
            color: @marso-gray-50;
            position: absolute;
            top: -8px;
        }

        >.text {
            line-height: 40px;
            height: inherit;
            overflow: hidden;

            &.default {
                color: @marso-gray-80;
            }
        }

        .item {
            padding: 10px !important;

            .label {
                display: none;
            }

            .title {
                position: static;
            }
        }

        .menu {
            border: none;
            margin: 0;
        }

        &.search-category {
            max-width: 120px;
            min-width: 120px;
            border-radius: 0.5rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .menu {
                width: 120px;
                min-width: 120px;
            }
        }

        &.search-car {
            max-width: 90px;
            min-width: 90px;
            border-radius: 0;

            .menu {
                width: 90px;
                min-width: 90px;
            }
        }
    }
}

#user-menu {
    &>.grid {
        margin: 0;
        float: right;

        &>.item {
            line-height: 10px;
            margin: 0 -1px 0 0;
            padding: 0.5rem 0;
            color: @white;
            max-width: 6rem;
            min-width: 6rem;
            text-transform: uppercase;

            &:hover,
            &.hover {
                background-color: @marso-blue;

                &:first-child .menu-content-wrapper {
                    border-color: @marso-blue;
                }

                .menu-content-wrapper {
                    border-color: @marso-blue;
                }
            }

            &:first-child .menu-content-wrapper {
                border-left: 1px solid @marso-light-red;
            }

            .menu-content-wrapper {
                height: 6rem;
                padding: 1rem 0;
                border-right: 1px solid @marso-light-red;
            }

            .icon {
                font-size: 2.4rem;
                height: 2.8rem;
                display: block;
                margin: 0 auto;
            }

            small {
                font-size: 1.2rem;
            }
        }
    }

    #menu-button-my-cart {

        &:hover,
        &.hover {
            .marso-icon-shopping_cart {
                .counter {
                    background-color: @marso-red;
                }
            }
        }

        .marso-icon-shopping_cart {
            position: relative;

            .counter {
                position: absolute;
                bottom: 12px;
                right: -10px;
                margin-top: inherit !important;
                background-color: @marso-blue;
                color: @white;
            }
        }
    }

    .ui.popup.header-popup {
        border-radius: 0;
        background-color: @marso-blue;
        color: @white;
        margin-top: 0;
        border: none;
        right: 1rem !important;
        padding: 1rem 0;

        .menu {
            width: auto;

            .item {
                color: @white;
                margin: 0;
                padding: 0.5rem 1rem;

                .header {
                    text-transform: uppercase;
                    border-bottom: 2px solid @marso-dark-blue;
                    padding-bottom: 1rem;
                    margin: 0;
                }
            }

            a.item:hover {
                background-color: @marso-dark-blue;
            }
        }

        &:before {
            content: none;
            display: none;
        }

        &.my-service,
        &.my-car {
            padding-top: 20px;
            padding-bottom: 15px;

            .item.description {
                margin-top: 15px;
            }

            .item.goto-button {
                margin-top: 15px;
            }

            .item .button {
                background-color: @marso-brand;
                text-transform: uppercase;
                color: @white;
                text-align: left;
            }

            .item.description .right.content {
                font-weight: bold;
            }

            .select-service,
            .select-car {
                color: @marso-gray-85  !important;

                .default.text {
                    color: @marso-gray-85;
                }

                .item {
                    color: @marso-gray-85;

                    input.search::placeholder {
                        color: @marso-gray-85;
                    }
                }
            }
        }
    }
}

#logo-image img {
    max-height: 35px;
}

// main dropdown menu
.main-menu-box {
    max-height: 45rem;
    min-height: 45rem;

    .store-menu {
        height: 5rem;
        padding: 5%;

        .item {
            display: inline-block;
            width: 49%;
            padding: 0 4%;

            img {
                width: 100%;
            }
        }
    }

    .panel-left {
        z-index: 99;
        background-color: @marso-brand-blue;

        .menu {
            padding: 1rem 0;
            font-size: 1.6rem;

            .item {
                display: block;
                color: @white;
                padding: 0.5rem 1rem 0.5rem 2rem;
                height: 3.2rem;
            }

            .main.item {
                height: 4rem;
                font-weight: bold;
                font-size: 2rem;
                padding: 0 1.5rem;
                text-transform: uppercase;

                .text {
                    height: 4rem;
                    display: block;
                    border-bottom: 1px solid @marso-dark-blue;
                    padding: 1rem 0;
                }
            }

            .main.item:first-child .text {
                border-top: none;
            }

            .main.item.last .text {
                border-bottom: none;
            }

            .item.active {
                background-color: @marso-dark-blue;
            }

            .main.item.active .text {
                border-color: @marso-dark-blue;
            }

            .item.need-subbox {
                .icon {
                    float: right;
                }
            }
        }
    }

    .right-subbox {
        z-index: 99;
        background-color: @white;
        background-position: bottom right;
        background-repeat: no-repeat;

        .content-wrapper {
            padding: 3rem;

            .menu {
                padding-bottom: 1rem;

                .item {
                    display: block;
                    padding: 0.5rem 0 0;
                    color: @marso-gray-80;
                }

                a.item:hover {
                    color: @marso-dark-blue;
                }

                .item.main {
                    border-bottom: 1px solid @marso-dark-blue;
                    text-transform: uppercase;
                    font-size: 1.8rem;
                    line-height: 2rem;
                    font-weight: bold;
                    padding-bottom: 0.5rem;
                    margin-bottom: 0.5rem;
                }
            }
        }

        .header {}

        .ui.menu {
            margin: 0;

            .item {
                margin: 0 !important;
                padding: 0.5rem 0;
            }
        }

    }
}
