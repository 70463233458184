.news-page {
    padding-bottom: 5rem;

    >.main {
        border-right: 1px solid @marso-gray-06;
        padding-right: 0;
        padding-bottom: 5rem;
    }

    >.sidebar {
        padding-left: 1.5rem;
        padding-top: 2.5rem;
    }
}

#content-news {
    .cover-image .image,
    .cover-image>picture
    {
        border-bottom: 2px solid @marso-red;
        margin: 0 auto;
    }

    .cover-image {
        position: relative;
        //height: 500px;
    }

    .meta-data {
        margin-bottom: 1rem;

        .label {
            font-size: 1.2rem;
            font-weight: normal;

            &.dark-gray {
                font-weight: bold;
            }
        }

        .meta-date.column {
            padding-right: 2rem !important;
        }
    }

    .body {
        img {
            max-width: 100%;
        }
    }
}

.news {
    .container.main {
        padding-top: 4rem;
        padding-bottom: 8rem;
    }

    .pager-wrapper:last-child {
        padding-top: 5rem;
    }
}

.content-news.teaser {
    box-shadow: none;
    border-radius: 1rem;
    color: @marso-gray-80;

    > :first-child {
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 1rem !important;
    }

    &:hover {
        box-shadow: none !important;
        transform: none !important;

        >.title {
            background-color: @marso-red;

            h2 {
                color: @white;
            }
        }

        >.continue {
            color: @marso-red;
        }
    }

    >.image {
        border-bottom: 2px solid @marso-gray-80;

        img {
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }
    }

    >.title {
        border-bottom: 2px solid @marso-gray-80;
        border-top: none;

        h2 {
            font-size: 3rem;
            font-weight: 600;
        }
    }

    >.body-teaser {
        background-color: @marso-gray-06;
        border-top: none;
    }

    >.continue {
        border-top: 2px solid @marso-gray-10;
        background-color: @marso-gray-06;
        border-bottom-left-radius: 1rem !important;
        border-bottom-right-radius: 1rem !important;
        color: @marso-gray-40;
    }
}

#content-news {
    .sidebar {
        margin-top: 4rem !important;
        .sidebar-block>.news-tags {
            .tag {
                margin-bottom: 1rem;
                font-weight: normal;
            }
        }
    }
}
