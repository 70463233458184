/*
 * service site
 */
#service-site {
    #service-site-header {
        position: relative;

        >.title.container {
            position: relative;

            .header {
                position: absolute;
                bottom: 20px;
                color: @white;
                text-shadow: 0px 0px 8px @black;

                &.noimage {
                    position: static;
                    padding-top: 2rem;
                }
            }
        }
    }

    #service-site-image {
        margin: auto;
    }

    #service-site-images {
        padding-top: 3rem;

        .list {
            .item {
                margin-left: 30px;
                padding: 0;
                position: relative;

                .overlay {
                    display: none;
                }
            }

            .item.active {
                .overlay {
                    display: block;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: fade(@black, 50%);

                    &:before {
                        content: "";
                        position: absolute;
                        left: 85px;
                        bottom: 0px;
                        width: 10px;
                        height: 10px;
                        border-style: solid;
                        border-width: 0 10px 10px 10px;
                        border-color: transparent transparent @white transparent;
                    }
                }
            }
        }
    }

    .contact-informations {
        padding-top: 3rem;
        font-size: 2rem;

        .contact-informations-list {
            padding-right: 3rem;

            .item {
                border-top: 1px solid @marso-gray-20;
                padding: 1.5rem 0;
                margin: 0;
                line-height: 5rem;
            }

            .item:last-child {
                border-bottom: 1px solid @marso-gray-20;
            }

            .item.openings {
                line-height: 2rem;
            }
        }

        >.column {
            padding-bottom: 0;

            &.line {
                padding-top: 0;
            }
        }
    }

    #available-services {
        padding-top: 8rem;

        header {
            padding-bottom: 3rem;
        }

        .service-type-block {
            margin-top: 3rem !important;
            border-radius: 4px;
            background-color: @marso-gray-10;

            .service-type-icon.column {
                background-color: @marso-gray-80;
                color: @white;
                border-right: 4px solid @marso-red;
                text-align: center;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                min-height: 8rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .service-type-title.column {
                .header {
                    padding-left: 2rem;
                    font-size: 2rem;
                }
            }

            .service-type-services {
                >.grid {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }

                .service {
                    a {
                        display: block;
                        background-color: @white;
                        border-radius: 4px;
                        color: @marso-gray-85;
                        margin: 0.5rem;
                        padding: 1rem 1rem;
                    }
                }
            }
        }
    }

    .column {
        position: relative;

        #map {
            background-color: @marso-gray-06;
            width: 100%;
            height: 100%;

            .loading.icon {
                position: absolute;

                @import "themes/default/elements/icon.variables";
                @sub: round(@emSize * @big) / 2;

                top: calc(~"50% - "@sub);
                left: calc(~"50% - "@sub);
            }

            #map-gui {
                width: auto;
            }
        }
    }

    .service-site-description {
        padding-top: 3rem;
        padding-bottom: 6rem;
        font-size: 2rem;
    }
}

.service-sites {
    >div {
        position: relative;

        #map {
            height: 700px;
            background-color: @marso-gray-06;

            .loading.icon {
                position: absolute;
                @import "themes/default/elements/icon.variables";
                @sub: round(@emSize * @huge) / 2;

                top: calc(~"50% - "@sub);
                left: calc(~"50% - "@sub);
            }
        }
    }

    .page-teaser {
        padding-top: 3rem;
    }

    .page-attachments {
        padding-top: 3rem;
    }

    .page-content {
        padding-top: 3rem;
    }

    .services-table {
        padding-top: 3rem;
    }
}
