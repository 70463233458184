.uvc-widget-container {
    display: block;
    position: fixed;
    top: 15%;
    right: 1rem;
    z-index: 10;
    width: 15rem;
    max-width: 90%;

    .uvc-widget {
        margin-top: 0 !important;
        max-width: 100%;
        padding: 1rem;
        padding-top: 4rem;

        .uvc-header {
            background-color: transparent;
            width: 70%;
            height: 5rem;
            display: block;
            position: absolute;
            top: -1.5rem;
            left: 15%;
            background-image: url('../images/glasses.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        &.message {
            border: 0;
            display: block;
            box-shadow: 0 0 2rem fade(@marso-gray-80, 50%);
            font-weight: normal;

            .counter-block {
                font-weight: bold;
                color: @marso-red;
            }

            .uvc-counter {
                font-weight: bold;
                padding: 0 0.5rem;
            }
        }
    }
}

body.product-page {
    .product-image-wrapper {
        .uvc-widget-container {
            position: absolute;
            top: 4rem;
            right: 4rem;
        }
    }
}

html.mobile-layout {
    body.discount-page {
        .content-discount {
            .uvc-widget-container {
                position: initial;
                max-width: 100%;
                width: auto;
                margin: 4rem 0 2rem 0;
            }
        }
    }
}
