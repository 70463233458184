#page-footer {
    background-color: @marso-gray-80;
    color: @white;

    .modal {
        color: @textColor;
    }

    #footer-top {
        padding-top: 20px;

        h3 {
            text-transform: uppercase;
            font-size: 16px;
            padding-bottom: 10px;
            border-bottom: 1px solid @marso-brand;
        }

        >.ui.container>.ui.grid {
            margin-top: 0;
            margin-bottom: 0;
        }

        .menu {
            width: 100%;

            .item {
                color: @white;
                font-size: 15px;
                margin: 0;
                padding: 2px 0;
            }

            a.item:hover {
                color: @marso-brand;
            }
        }

        #footer-brands {
            margin-left: 0;

            .grid {
                margin-top: 0;
                max-width: 100%; // ie11 fix

                .column {
                    padding-top: 0;
                    padding-bottom: 0;

                    .item {
                        padding: 0.2rem 0.5rem 0.2rem 0;
                    }
                }
            }
        }

        #footer-social-icons {
            margin-left: 0;

            .item:first-child {
                padding-left: 0;
            }

            .item {
                padding: 0 0.5rem;

                .button {
                    padding: 1rem;
                    line-height: 2rem;

                    .icon {
                        vertical-align: middle;
                        font-size: 2rem;
                        width: 2rem;
                    }
                }
            }
        }

        #newsletter-subscribe-form {

            input {
                background-color: @marso-gray-60;
                color: @white;
                font-size: 1.6rem;
                padding: 1rem 2rem;

                &::placeholder {
                    text-transform: uppercase;
                    color: @marso-gray-30;
                }
            }

            button {
                text-align: left;
                text-transform: uppercase;
            }
        }
    }


    #footer-center {
        background-color: @marso-gray-85;

        .grid {
            margin-top: 0;
            margin-bottom: 0;

            .column {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .menu {
            margin-top: 0;
            margin-bottom: 0;

            .item {
                height: 50px;
                color: @white;
                font-weight: bold;
                text-transform: uppercase;

            }

            a:hover {
                color: @marso-brand;
            }
        }
    }

    #footer-bottom {
        font-size: 1.1rem;

        .container {
            text-align: center;
            padding: 3px 0;

            .item {
                color: @white;
                border-right: 1px solid @white;
                padding: 0 12px;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }
}
