@import "themes/default/globals/site.variables";
@import "site/globals/site.variables";

#service-offer-request-page {

    .ui.input.dropdown.field-input.selection .text{
        width:90%;
        white-space: nowrap !important;
        overflow:hidden;
    }
}

#service-offer-request-modal {
    border-radius: 25px;
    padding: 2rem;
    .header {
        text-transform: uppercase;
        font-size: 3rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid @marso-gray-10;
        font-weight: normal;
    }
    .content {
        font-size: 1.8rem;
    }
    .footer {
        //padding-top: 1rem;
        //border-top: 1px solid #e5e5e5;
    }
    .actions {
        text-align: center;
        border-top: 1px solid @marso-gray-10;
        background: none;
        .button {
            text-transform: uppercase;
        }
    }

}
