#newsletter-page,
#newsletter-subscribe-modal {
    .close {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.75rem;
        height: 3rem;
        padding: 0.75rem 0.5rem 0.5rem;
        width: 3rem;
        line-height: 2rem;
        background-color: @white;
        color: @marso-gray-80;
    }

    .description-wrapper {
        padding-top: 3rem;
        padding-bottom: 6rem;

        .description {
            .title {
                font-size: 3rem;
                font-weight: normal;
            }

            .body {
                font-size: 2rem;
            }
        }
    }

    .form-wrapper-panel {
        background-color: @marso-gray-10;
        margin-top: 10rem;
        margin-bottom: 1rem;

        .floating-icon-wrapper {
            .floating-icon {
                position: absolute;
                top: -10rem;
            }
        }

        .floating-title-wrapper {
            padding-bottom: 3rem !important;

            .floating-title {
                position: absolute;
                top: -10rem;
            }
        }
    }
}

#newsletter-form {
    min-height: 15rem;
}

#newsletter-subscribe-modal {}

#newsletter-modal {
    padding: 1rem;

    .form-panel-wrapper {}

    .form-block {
        .checkbox-field {
            .checkbox-label {
                height: auto;
            }
        }
    }
}
