.discounts-contents {
    padding-top: 3rem;

    .discounts-title {
        font-size: 8.4rem;
    }

    .quicklinks {
        padding-top: 3rem;

        .quicklinks-menu {
            border-top: 2px solid @marso-gray-06;
            border-bottom: 2px solid @marso-gray-06;

            >.column {
                :before {
                    content: "";
                    display: block;
                    border-right: 1px solid @marso-gray-60;
                    height: 2rem;
                    position: absolute;
                    top: 1.5rem;
                    right: 0;
                }
            }

            >.column:last-child :before {
                display: none !important;
            }

            a {
                display: block;
                height: 5rem;
                line-height: 5rem;
                color: @marso-gray-60;
                font-size: 2rem;

                &:hover {
                    color: @marso-red;
                }
            }
        }
    }

    .page-content {
        .show-description {
            color: @marso-red;
            padding: 0.5rem 0;
        }
    }
}

#discounts-discounts-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.discount-teaser {
    .content {
        .title {
            font-weight: bold;
            padding-bottom: 2rem;
            font-size: 2rem;

            a {
                color: @marso-gray-85;

                &:hover {
                    color: @marso-red;
                }
            }
        }

        .continue {
            font-weight: bold;
            color: @marso-gray-80;

            &:hover {
                color: @marso-red;
            }
        }
    }
}

.content-discount {
    .content-panel {
        position: relative;
        padding-bottom: 3rem;

        >.goto-down {
            position: relative;
            text-align: center;
            clear: both;

            >.icons {
                cursor: pointer;
                position: absolute;
                top: -6.4rem;
                width: 6.4rem;
                height: 6.4rem;
                margin: 0 auto 0 auto;
                left: 0;
                right: 0;
                padding: 0;
                background-color: @marso-red;
                box-shadow: 0px 0px 10px 3px fade(@marso-gray-80, 50%);
                border-radius: 100%;

                >.icon {
                    width: 6.4rem;
                    height: 6.4rem;
                    font-size: 3rem;
                    line-height: 6.4rem;
                    vertical-align: middle;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .title {
            text-align: center;
            font-size: 4rem;
            font-weight: bold;
            line-height: 1.8em;
            padding: 3rem 1rem;
            border-bottom: 2px solid @marso-gray-06;
        }

        .faq-list {
            .row {
                border-bottom: 2px solid @marso-gray-06;
            }

            .question {
                color: @marso-red;
                font-size: 2.25rem;
                line-height: 4rem;
                padding: 1rem;
            }

            .answer {
                position: relative;
                top: 50%;
                transform: translateY(-50%);

                a {
                    color: @marso-red;
                }
            }
        }

        .description-wrapper {
            padding: 3rem 0;

            .description {
                padding-bottom: 2rem;
                border-bottom: 2px solid @marso-gray-06;
            }
        }
    }
}

.content-discount.landing-page {
    .landing-header {
        min-height: 60rem;

        .landing-page-title {
            padding-top: 3rem;
            font-size: 7rem;
            font-weight: bold;
            color: @white;
            line-height: 1.5em;
            text-shadow: 5px 1px 0px fade(@marso-gray-85, 95%),
                -1px -1px 5px fade(@marso-gray-85, 90%);

            .center-title {
                font-size: 15rem;
                line-height: 1.6em;
            }
        }

        .landing-page-title-placeholder {
            min-height: 60rem;
        }

        .highlight-box {
            padding: 3rem 0 6rem 0;

            .text-panel {
                min-height: 6rem;
                background-color: @marso-green;
                color: @white;
                padding: 3rem 10rem;
                border-radius: 15rem;

                .description-text {
                    font-size: 2.8rem;
                    line-height: 3.5rem;

                    strong {
                        display: block;
                    }
                }
            }
        }

        .highlight-box.with-image {
            .image-panel {
                position: relative;
                float: left;
                min-height: 10rem;
                min-width: 10rem;

                .image {
                    position: absolute;
                    top: -20%;
                    left: 0;
                    width: 20rem;
                }
            }

            .text-panel {
                margin-left: 10rem;
                padding-left: 15rem;
            }
        }
    }

    .content-panel {
        padding: 2rem;
        background-color: @white;
        border-top-right-radius: 2rem;
        border-top-left-radius: 2rem;
        box-shadow: 0px 0px 10px 5px fade(@marso-gray-80, 50%);
    }
}

#discounts-top-sizes-section {
    padding-top: 3rem;
    padding-bottom: 2rem;

    .bg-colored {
        .grided>.column {
            border-left: 1px solid @marso-gray-06;
            border-right: 1px solid @marso-gray-06;
            padding: 1rem 0 0.5rem !important;
        }

        .grided>.column:first-child {
            border-left: 2px solid @marso-gray-06;
        }

        .grided>.column:last-child {
            border-right: 2px solid @marso-gray-06;
        }

        &.title {
            background-color: @marso-gray-10;

            .page-title {
                padding: 3rem;
                margin: 0;
            }
        }

        &.sizes {
            background-color: @marso-gray-10;
            font-weight: bold;

        }

        &.car {
            background-color: @marso-gray-10;

            .grided>.column {
                min-height: 15rem;
                padding: 1rem 0 !important;

                a {
                    display: block;
                    color: @marso-gray-80;
                }
            }
        }

        &.suv-4x4 {
            background-color: @marso-gray-20;

            .grided>.column {
                min-height: 15rem;
                padding: 1rem 0 !important;

                a {
                    display: block;
                    color: @marso-gray-80;
                }
            }
        }

        &.van {
            background-color: @marso-gray-30;

            .grided>.column {
                min-height: 15rem;
                padding: 1rem 0 !important;

                a {
                    display: block;
                    color: @marso-gray-80;
                }
            }
        }

        &.line {
            background-color: @marso-gray-06;
            height: 2px;
        }

        &.bottom {
            background-color: @marso-gray-10;
            height: 1rem;
        }

        .column-header-image {
            min-width: 18rem;
        }
    }
}

#discounts-description-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

#discount-products-section-wrapper {
    margin: 0 -2.1rem;
    min-height: 50rem;

    .ui.dimmer {
        z-index: 10;
        justify-content: normal;
        vertical-align: auto;

        .content {
            padding: 3rem 0;
        }
    }

    .dimmer-icons {
        .icon {
            font-size: 10rem;
        }
    }
}

#discounts-discount-products-section {
    padding: 3rem 2rem 9rem 2rem;
}

.content-discount {
    .counters-block {
        border-bottom: 2px solid @marso-gray-06;
    }

    .countdown-wrapper {
        padding: 2rem 1rem;
        height: 10rem;

    }
}

.countdown-wrapper {
    height: 8rem;
    padding: 1rem;
    display: flex;

    .countdown-icon {
        width: 6rem;
        height: 6rem;
        padding: 0.5rem;
        line-height: 1;
        vertical-align: middle;

        .icon {
            color: @marso-red;
            font-size: 4.5rem;
        }
    }

    .countdown {
        padding-left: 1rem;

        .countdown-counters {
            height: 4rem;
            width: 22.6rem;
            display: flex;

            .countdown-number {
                display: inline-block;
                width: 3rem;
                height: 4rem;
                text-align: center;
                font-size: 3rem;
                font-weight: bold;
                padding-top: 0.5rem;
                background-color: @marso-gray-20;
                background: linear-gradient(to bottom, @marso-gray-10 0%, @marso-gray-10 50%, @marso-gray-20 50%, @marso-gray-20 100%);

                &.left {
                    border-radius: 0.5rem 0 0 0.5rem;
                }

                &.right {
                    border-radius: 0 0.5rem 0.5rem 0;
                    margin-left: 0.2rem;
                }
            }

            .countdown-divider {
                display: inline-block;
                width: 2rem;
                height: 4rem;
                text-align: center;
                font-size: 3rem;
                font-weight: bold;
                padding-top: 0.5rem;
            }
        }

        .countdown-labels {
            height: 2rem;
            width: 22.6rem;
            display: flex;

            .countdown-label {
                display: inline-block;
                width: 6.2rem;
                text-align: center;
            }

            .countdown-divider {
                display: inline-block;
                width: 2rem;
                text-align: center;
                font-size: 3rem;
                font-weight: bold;
                padding-top: 0.5rem;
            }
        }
    }

    .countdown-text {
        padding: 0.5rem 1rem;
        font-size: 2rem;
    }
}

.countdown-wrapper.black-friday {
    color: #fff;
    align-items: center;
    justify-content: center;

    .countdown-counters {
        height: 3rem;

        .countdown-number {
            background: none;
            color: @marso-red;
        }
    }

    .countdown-labels {
        .countdown-label {
            font-weight: bold;
        }
    }
}

.product-descriptions {
    .discount-url {
        position: relative;
        display: block;

        .details-button {
            display: block;
            position: absolute;
            right: 15%;
            bottom: 10%;
            text-transform: uppercase;
        }
    }
}
