.rim-search {
    .rim-search-container {
        padding-bottom: 3rem;
    }

    .car-form {
        .select-field {

            .dropdown.search,
            .dropdown.input {
                max-height: 5rem;
                line-height: 3rem;

                >.text {
                    max-width: 100%;
                }
            }

            .dropdown.search {
                &.loading {
                    .dropdown.icon {
                        top: 3rem !important;
                        right: 3rem !important;

                        &:before {
                            top: 2rem !important;
                            left: 1rem !important;
                            height: 2rem;
                            width: 2rem;
                        }

                        &:after {
                            top: 2rem !important;
                            left: 1rem !important;
                            height: 2rem;
                            width: 2rem;
                        }
                    }
                }
            }

            .dropdown.icon {
                padding: 1rem !important;
                line-height: 2.5rem;
                top: 2.5rem;
                right: 2.5rem;
            }
        }

        .alcar-model-field,
        .alcar-type-field {
            .input {
                .text {
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }

    .rim-results-section {
        background-color: @marso-gray-10;
        color: @marso-gray-80;
        //min-height: 14rem;
        padding: 3rem 0;

        .empty-message {
            font-size: 2.4rem;
            //line-height: 100px;
        }
    }

    .car-image-section {
        padding: 3rem 0;
        background-color: @marso-gray-40;
        color: @white;

        .empty-message {
            font-size: 2.4rem;
        }

        .alcar-car-image {
            background-color: @white;
        }

        .rim-container {
            position: relative;
            background-color: @white;
            color: @marso-gray-80;
            width: 100%;
            height: 100%;
            z-index: 5;

            #request-send-form {
                .field .prompt {
                    position: absolute;
                    z-index: 3;
                    right: 20px;
                    top: -30px;
                }
            }

            #add-to-cart-form {
                .quantity-wrapper {
                    .quantity-stepper-wrapper {
                        margin-right: 5rem;
                    }
                }
            }

            .request-send-success,
            .shop-success {
                padding: 10px;

                a {
                    color: @marso-red;
                }
            }

            &::before {
                position: absolute;
                content: " ";
                display: block;
                right: -36px;
                top: 89px;
                background-color: transparent;
                border-top: 36px solid transparent;
                border-right: none;
                border-left: 36px solid @marso-gray-80;
                border-bottom: 36px solid transparent;
                z-index: 1;
                opacity: 0.4;
            }

            &::after {
                z-index: 2;
                position: absolute;
                content: " ";
                display: block;
                right: -35px;
                top: 90px;
                background-color: transparent;
                border-top: 35px solid transparent;
                border-right: none;
                border-left: 35px solid @white;
                border-bottom: 35px solid transparent;
            }
        }
    }
}

#rim-search-results {
    background-color: @marso-gray-10;

    .controls {
        .hs-left-button {
            .icon {
                padding-right: 4px;
            }
        }

        .hs-right-button {
            .icon {
                padding-left: 4px;
            }
        }
    }

    .rim-search-results {
        white-space: nowrap;
        padding: 0;
        overflow: hidden;

        .rim-search-result {
            position: relative;
            width: 15rem;
            display: inline-block;
            margin: 1rem;

            .storage-status {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0;
                width: 25px;
            }

            .sizes {
                text-align: center;
                width: 100%;
                color: @marso-gray-85;
                background-color: @white;
            }

            .button {
                cursor: pointer;
                border: 3px solid @white;
            }

            .button:hover {
                border: 3px solid @marso-gray-30;
            }

            .button.active {
                border: 3px solid @marso-red;
            }
        }
    }
}
